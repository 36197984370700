import { render, staticRenderFns } from "./mechanism.vue?vue&type=template&id=22027986&scoped=true&"
import script from "./mechanism.vue?vue&type=script&lang=js&"
export * from "./mechanism.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22027986",
  null
  
)

export default component.exports