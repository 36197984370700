<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-08 16:59:01
 * @LastEditTime: 2021-06-18 16:09:02
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="mechanism">
    <!-- <router-view></router-view> -->
    <LogoTitle msg="机构动态"></LogoTitle>
    <div class="list">
      <NewsCard
        source="Mechanism"
        v-for="item in list"
        :info="item"
        :key="item.id"
      ></NewsCard>
    </div>
    <div class="sljr-pagination">
      <SinglePagination
        :total="total"
        v-if="total <= pageSize"
      ></SinglePagination>
      <el-pagination
        :hide-on-single-page="isHide"
        :page-size="pageSize"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentPage: 1,//当前页数
      pageSize: 10,//每页显示条目个数
      total: 0,//总条目数
      isHide: true,//只有一页时是否隐藏
      list: ''
    }
  },
  created () {
    document.title = "机构动态"
    this.getList();
  },
  methods: {
    /**
   * @description: 机构动态列表
   * @param  {categoryId: '1376792129237585921',categoryType,pageNo,pageSize}
   * @return {*}
   */
    getList () {
      let params = {
        categoryId: '1376792129237585921',
        categoryType: '1',
        pageNo: this.currentPage,
        pageSize: this.pageSize
      }
      this.$api.getContentList(params).then((res) => {
        this.total = res.data.data.total
        this.list = res.data.data.rows
      })
    },
    //分页
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getList()
      document.body.scrollIntoView()
    },
  },
  watch: {
    // $route (n) {
    //   console.log(n)
    //   if (n.name == 'MecArticle') {

    //   }
    // }
  }
}
</script>

<style scoped lang='scss'>
</style>